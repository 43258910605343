import React, { Component } from "react";
import { VictoryPie, VictoryContainer } from "victory";
import { defaultTheme } from "../../../..";
import PropTypes from "prop-types";

/** This pie graph is created using [Victory graph library by Formidable](https://formidable.com/open-source/victory/docs/victory-pie/)*/
export default class Pie extends Component {
  static propTypes = {
    /** The description to use for accessibility purposes. */
    description: PropTypes.string,
    /** Whether any items are hovered/focussed on right now */
    anySlicesHovered: PropTypes.bool,
    /** Returns whether the passed slice is hovered/focussed on right now */
    isHoveredItem: PropTypes.func,
    /** The data to render in the graph - see Victory docs for more information*/
    data: PropTypes.array,
    /**
     * The labels on the pie graph data sections. Will be attributed to the sections in index order
     * e.g ["first", "second", "third"]
     */
    labels: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    /** Called on hover/focus of a pie slice */
    handleHoverItem: PropTypes.func,
    selectedSegments: PropTypes.array
  };
  getDescription = () => {
    const { description } = this.props;
    return description ? description : "";
  };

  anySlicesHovered = () => {
    const { anySlicesHovered } = this.props;
    return anySlicesHovered;
  };
  isHoveredSlice = (x) => {
    const { isHoveredItem } = this.props;
    return isHoveredItem(x);
  };
  getData = () => {
    const { data } = this.props;
    if (!data) return [];
    return data;
  };
  getColorScale = () => {
    const { colorScale } = this.props;
    return colorScale;
  };
  handleHoverSlice = (name) => {
    const { handleHoverItem } = this.props;
    if (handleHoverItem) handleHoverItem(name);
  };
  getLabels = () => {
    const { labels } = this.props;
    return labels ? labels : [];
  };

  handleClickSlice = (name) => {
    const { handleClickSlice } = this.props;
    return handleClickSlice(name);
  };

  selectedSegments = () => {
    const { selectedSegments } = this.props;
    return selectedSegments ? selectedSegments : [];
  };

  isSelectedSegment = (segment) => {
    const selectedSegments = this.selectedSegments();
    if (selectedSegments.length === 0) return false;
    return (
      selectedSegments.find(
        (selectedSegment) => selectedSegment === segment
      ) !== undefined
    );
  };

  render() {
    return (
      <VictoryPie
        containerComponent={<VictoryContainer desc={this.getDescription()} />}
        animate={
          !this.props.ignoreAnimation && {
            duration: 150
          }
        }
        style={{
          data: {
            fillOpacity: ({ datum }) =>
              this.selectedSegments().length > 0
                ? this.isSelectedSegment(datum.x)
                  ? 1
                  : 0.4
                : 1,
            stroke: ({ datum }, i) => {
              return this.isHoveredSlice(datum.x)
                ? defaultTheme.agLightBlue
                : datum.fill;
            },
            strokeWidth: 3,
            fill: ({ datum }) => datum.fill
          },
          labels: {
            fontSize: 20,
            padding: 20,
            fontWeight: 600,
            fontFamily: defaultTheme.baseFont
          }
        }}
        width={450}
        data={this.getData()}
        labels={this.getLabels()}
        eventKey="name"
        events={[
          {
            target: "data",
            eventHandlers: {
              onMouseEnter: () => {
                return [
                  {
                    target: "data",
                    mutation: (props) => {
                      return this.handleHoverSlice(props.datum.x);
                    }
                  }
                ];
              },
              onMouseLeave: () => {
                return [
                  {
                    target: "data",
                    mutation: (props) => {
                      return this.handleHoverSlice(null);
                    }
                  }
                ];
              },
              onClick: (props) => {
                return [
                  {
                    target: "data",
                    mutation: (props) => {
                      return this.handleClickSlice(props.datum.x);
                    }
                  }
                ];
              }
            }
          }
        ]}
      />
    );
  }
}
